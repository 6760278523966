<template>
  <b-col cols="12" class="py-32">
    <p class="mb-8 hp-p1-body hp-text-color-black-60">
      摩卡科技有限公司
    </p>
  </b-col>
</template>

<script>
import { BRow, BCol, BLink } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
  },
};
</script>
